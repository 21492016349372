$(document).ready(function () {

  /**************************************************
   * loading screen
   **************************************************/
  //$('.loading-screen').fadeOut();
  /*$(window).on('beforeunload', function() {
    $('.loading-screen').fadeIn();
  })*/

  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  initMobileMenu();

})
window.captchaCallback = function(response) {
  $(".g-recaptcha").val(response);
};

function initMobileMenu(){
  if($("#mobileMenuNav").length > 0)
  {
    var menu = new MmenuLight($("#mobileMenuNav").get(0));

    /*mmenu.create("(max-width: 1200px)");

     mmenu.init("current");*/
    var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        // title: 'Menu'
    });

    var drawer = menu.offcanvas({
        position: 'right'
    });

    $('#btnMobileMenu').on("click", function(ev) {
        $('#mobileMenuNav').css("display", "block");
        drawer.open();

        ev.preventDefault();
        ev.stopPropagation();
    });  
  }
}